<script setup lang="ts">
import type { Wallet } from '@solana/wallet-adapter-vue'
import { evaClose } from '@quasar/extras/eva-icons'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-vue'
import { camelCase } from 'lodash-es'
import { PhantomWalletName, SolflareWalletName } from 'wallets'

import { OKXWalletName } from 'wallets/adapters/OkxWalletAdapter'
import cloverSvg from '~/assets/img/wallets/clover.svg'
import ledgerDarkSvg from '~/assets/img/wallets/ledger.svg'
import mathWalletDarkSvg from '~/assets/img/wallets/mathwallet.svg'
import { shortenAddress } from '~/utils'

defineProps({
  onlyDisconnect: Boolean,
})

type WalletOpts = {
  priority?: number
  icon?: string
  lightIcon?: string
  darkIcon?: string
  deepLink?: string
}

type ExtendedWallet = Wallet & WalletOpts

const walletOpts: Record<string, WalletOpts> = {
  phantom: {
    priority: 20,
    deepLink: 'https://phantom.app/ul/browse/{uri}?ref={ref}',
  },
  solflare: {
    priority: 20,
    deepLink: 'solflare://ul/v1/browse/{uri}?ref={ref}',
  },
  okxWallet: {
    deepLink: 'okx://wallet/dapp/details?dappUrl={host}',
    priority: 5,
  },
  bitget: {
    deepLink: 'https://bkcode.vip?action=dapp&url={uri}',
    priority: 5,
  },
  walletConnect: {
    priority: 10,
  },
  mathWallet: {
    lightIcon: mathWalletDarkSvg,
  },
  ledger: {
    priority: 2,
    lightIcon: ledgerDarkSvg,
  },
  clover: {
    icon: cloverSvg,
  },
  brave: {
    priority: 5,
  },
  backpack: {
    priority: 5,
  },
  coinbase: {
    deepLink: 'https://go.cb-w.com/dapp?cb_url={uri}',
    priority: 5,
  },
  tokenPocket: {
    deepLink: `tpdapp://open?params=${
      JSON.stringify({
        url: '{url}',
        chain: 'Solana',
        source: '{url}',
      })}`,
    priority: 5,
  },
}

const recommended = new Set(['Solflare', 'Phantom'])

const icons = {
  close: evaClose,
}

const route = useRoute()
const { userAgent, isWebView, isMobileOs } = useMobileDetect()
const isMobile = computed(() => isMobileOs.value && !isWebView.value)

const wallet = useWallet()
const { connected, connecting } = wallet

const dialog = ref(false)
const connectingTimer = ref<any>(0)

function isActiveWallet(w: Wallet) {
  return w.readyState === WalletReadyState.Installed || w.readyState === WalletReadyState.Loadable
}

function isMobileRelevant(w: ExtendedWallet) {
  return isMobile.value && (w.deepLink || w.adapter.name === 'WalletConnect')
}

function cancelTimer() {
  if (connectingTimer.value > 0) {
    clearTimeout(connectingTimer.value)
  }
}

async function select(w: Wallet) {
  cancelTimer()
  await wallet.select(w.adapter.name)
  dialog.value = false
  connectingTimer.value = setTimeout(() => {
    connecting.value = false
  }, 10_000)
  await wallet.connect()
}

const webViewWallet = ref()
if (isWebView.value) {
  const ua = String(userAgent.value).toLowerCase()
  if (ua.includes('phantom')) {
    webViewWallet.value = PhantomWalletName
  } else if (globalThis.solflare) {
    webViewWallet.value = SolflareWalletName
  } else if (/okapp/i.test(ua)) {
    webViewWallet.value = OKXWalletName
  }
}

const walletAddress = computed(() => wallet.publicKey.value?.toBase58() ?? '')
const walletShortAddress = computed(() => shortenAddress(walletAddress.value, 5))
const wallets = computed<ExtendedWallet[]>(() =>
  [...wallet.wallets.value as ExtendedWallet[]]
    .map((w) => {
      const key = camelCase(w.adapter.name)
      if (walletOpts[key]?.icon) {
        w.adapter.icon = walletOpts[key].icon
      }
      if (!isDark.value && walletOpts[key]?.lightIcon) {
        w.adapter.icon = walletOpts[key].lightIcon
      }
      if (isDark.value && walletOpts[key]?.darkIcon) {
        w.adapter.icon = walletOpts[key].darkIcon
      }
      // only show deep links on mobile
      if (isMobile.value && walletOpts[key]?.deepLink) {
        w.deepLink = walletOpts[key].deepLink
          .replace('{uri}', encodeURIComponent(`${location.href}?wallet=${w.adapter.name}`))
          .replace('{ref}', encodeURIComponent(location.origin))
          .replace('{host}', location.host)
      }
      return w
    })
    .sort((a, b) => {
      const aPriority = walletOpts[camelCase(a.adapter.name)]?.priority ?? 1
      const bPriority = walletOpts[camelCase(b.adapter.name)]?.priority ?? 1
      return (
        bPriority
        - aPriority
        + ((isActiveWallet(b) ? 1 : 0) - (isActiveWallet(a) ? 1 : 0))
      )
    }),
)

const computedWallets = computed(() => {
  return wallets.value.reduce((acc: { installed: ExtendedWallet[], others: ExtendedWallet[] }, w) => {
    const isWebViewMatch = !webViewWallet.value || w.adapter.name === webViewWallet.value

    const isInstalled = recommended.has(w.adapter.name)
      || w.readyState === WalletReadyState.Installed
      || (isMobile.value && w.deepLink)

    if (isWebViewMatch && (isMobile.value ? isMobileRelevant(w) : true)) {
      if (isInstalled) {
        acc.installed.push(w)
      } else {
        acc.others.push(w)
      }
    }
    return acc
  }, { installed: [], others: [] })
})

watch(connected, (isConnected) => {
  if (isConnected) {
    cancelTimer()
  }
})

watch(() => route.query?.wallet, (w) => {
  if (w) {
    webViewWallet.value = w
  }
}, { immediate: true })

function disconnect() {
  dialog.value = false
  wallet.disconnect()
}

if (webViewWallet.value) {
  wallet.select(webViewWallet.value)
  wallet.connect()
}

function show() {
  // if (!connected.value && computedWallets.value.length === 1 && computedWallets.value[0]) {
  //   select(computedWallets.value[0])
  // } else {
  dialog.value = true
  // }
  Analytics.track({ event: 'Connect wallet', action: 'wallet.connect', label: 'Connect wallet' })
}

function ok() {
  dialog.value = false
}
</script>

<template>
  <q-btn
    v-if="connected && !onlyDisconnect"
    v-bind="$attrs"
    :class="$style.btn"
    :ripple="false"
    unelevated
    color="secondary"
    text-color="black"
    @click="show"
  >
    {{ walletShortAddress }}
  </q-btn>

  <q-btn
    v-else-if="connected && onlyDisconnect"
    v-bind="$attrs"
    :class="$style.btn"
    :ripple="false"
    unelevated
    color="secondary"
    text-color="black"
    @click="disconnect"
  >
    {{ $t('wallet.disconnect') }}
  </q-btn>

  <q-btn
    v-else
    v-bind="$attrs"
    :class="$style.btn"
    unelevated
    :ripple="false"
    :loading="connecting"
    color="secondary"
    text-color="black"
    @click="show"
  >
    {{ $t('wallet.connect') }}
  </q-btn>

  <q-dialog
    v-model="dialog"
    transition-duration="150"
    transition-show="fade"
    transition-hide="fade"
    class="wallets-list-dialog"
  >
    <q-card v-if="connected" :dark="isDark">
      <q-card-section class="relative-position">
        <div class="text-h6 text-center">
          {{ $t('stakeBox.yourWallet') }}
        </div>
        <q-btn
          padding="md"
          color="transparent"
          text-color="primary-gray"
          unelevated
          class="absolute-right"
          :icon="icons.close"
          size="md"
          @click="ok"
        />
      </q-card-section>
      <q-separator />
      <q-card-section class="row items-center">
        <copy-to-clipboard :text="walletAddress" />
        {{ walletAddress }}
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="q-gutter-md row justify-between">
          <q-btn outline rounded @click="disconnect">
            {{ $t('wallet.disconnect') }}
          </q-btn>
          <q-btn outline rounded @click="ok">
            {{ $t('common.ok') }}
          </q-btn>
        </div>
      </q-card-section>
    </q-card>

    <q-card v-else class="wallet-connect-card" :dark="isDark">
      <q-card-section class="wallet-connect__header">
        <div class="text-h6">
          {{ $t('wallet.connectTo') }}
        </div>
        <q-btn
          padding="md"
          text-color="primary-gray"
          unelevated
          class="absolute-right"
          :icon="icons.close"
          :dark="isDark"
          size="md"
          @click="ok"
        />
      </q-card-section>
      <q-separator />
      <q-card-section style="max-height: 80vh" class="scroll">
        <div class="wallet-connect-group">
          Recommended wallets
        </div>
        <q-table
          grid
          :rows="computedWallets.installed"
          :dark="isDark"
          row-key="name"
          hide-pagination
          hide-header
          :rows-per-page-options="[100]"
        >
          <template #item="{ row: w }">
            <div :key="`wallet-${w.adapter.name}`" class="col-12 col-md-6">
              <q-item
                clickable
                class="wallet-connect-item"
                :dark="isDark"
                :href="w.deepLink"
                @click="w.deepLink ? true : select(w)"
              >
                <q-item-section>
                  <div class="wallet-connect-item__name">
                    {{ w.adapter.name }}
                  </div>
                  <div class="wallet-connect-item__url">
                    {{ w.adapter.url }}
                  </div>
                </q-item-section>
                <q-item-section avatar>
                  <q-avatar square>
                    <img :src="w.adapter.icon" :alt="w.adapter.name">
                  </q-avatar>
                </q-item-section>
              </q-item>
            </div>
          </template>
        </q-table>
        <q-expansion-item
          v-if="computedWallets.others.length > 0"
          expand-separator
          label="More wallets"
          class="wallet-connect-group wallet-connect-group--others"
        >
          <q-table
            grid
            :rows="computedWallets.others"
            :dark="isDark"
            row-key="name"
            hide-pagination
            hide-header
            :rows-per-page-options="[100]"
          >
            <template #item="{ row: w }">
              <div :key="`wallet-${w.adapter.name}`" class="col-12 col-md-6">
                <q-item
                  clickable
                  class="wallet-connect-item"
                  :dark="isDark"
                  :href="w.deepLink"
                  @click="w.deepLink ? true : select(w)"
                >
                  <q-item-section>
                    <div class="wallet-connect-item__name">
                      {{ w.adapter.name }}
                    </div>
                    <div class="wallet-connect-item__url">
                      {{ w.adapter.url }}
                    </div>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-avatar square>
                      <img :src="w.adapter.icon" :alt="w.adapter.name">
                    </q-avatar>
                  </q-item-section>
                </q-item>
              </div>
            </template>
          </q-table>
        </q-expansion-item>
      </q-card-section>
      <q-separator />
      <q-card-section class="wallet-connect__footer">
        Don't have a wallet?
        <a target="_blank" href="https://solana.com/solana-wallets">Get started</a>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" module>
.btn.btn {
  min-width: 172px;
  width: max-content;
  height: 54px;
  white-space: nowrap;
  flex-wrap: nowrap;

  img {
    height: 0.6em;
    margin-right: 0.2em;
  }
}
</style>
